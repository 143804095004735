import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import { Norway, Sweeden } from '../components/Advisors'
import { initI18n } from '../translations'

const Advisors = props => {
  const i18n = initI18n(props.pageContext.instance, props.pageContext.lang)

  return (
    <Layout {...props}>
      <div className="main-content content-module">
        <Container>
          <h1>{i18n('advisors.heading_se')}</h1>
          <div className="advisors">
            <Sweeden />
          </div>

          <h1>{i18n('advisors.heading_no')}</h1>
          <div className="advisors">
            <Norway />
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default Advisors
